import '../../assets/styles/css/main.css'

function Error404() {
  return (
    <section className="hero has-background-black is-fullheight">
      <div className="hero-body">
        <div className="container has-text-centered">
          <div className="title is-3">
            <code className="has-text-white has-background-black">404</code>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Error404;