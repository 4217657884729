import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import Home from './view/Home';
import Error404 from './view/error/404';

function App() {
  return (
    <Router>
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="*">
            <Error404 />
          </Route>
        </Switch>
    </Router>
  );
}

export default App;