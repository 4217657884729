import '../assets/styles/css/main.css'
import { SiInstagram, SiTwitter, SiReddit, SiGithub, SiSteam } from "react-icons/si";

function Home() {
  return (
    <section class="hero has-background-black is-fullheight">
      <div class="hero-body">
        <div class="container has-text-centered">
          <div className="title is-3">
            <code className="has-text-white has-background-black">hello there</code>
          </div>
          <a href="https://instagram.com/nico261_/" target="_blank" rel="noopener noreferrer">
            <SiInstagram className="icon ig" />
          </a>
          <a href="https://twitter.com/nico261_" target="_blank" rel="noopener noreferrer">
            <SiTwitter className="icon tw" />
          </a>
          <a href="https://github.com/nico261" target="_blank" rel="noopener noreferrer">
            <SiGithub className="icon gh" />
          </a>
          <a href="https://steamcommunity.com/id/nico261/" target="_blank" rel="noopener noreferrer">
            <SiSteam className="icon st" />
          </a>
          <a href="https://reddit.com/user/nico261" target="_blank" rel="noopener noreferrer">
            <SiReddit className="icon rd" />
          </a>
        </div>
      </div>
    </section>
  );
}

export default Home;